import React, { useContext, useState } from 'react';

import Slider from './CustomSlider';
import Product from './Product';
import InlineLoader from './InlineLoader';
import notificationTypes from '../constants/modalNotificationConst';
import * as SegmentSource from '../constants/segmentSource';
import appContext from '../Context/appContext';
import useSegment from '../hooks/segment';
import { useDispatch, useSelector } from 'react-redux';
import { increaseURLstack, pushNotificationOverlap, pushOnTopIfNotPresent } from '../redux/slices/modalSlice';
import { setUrlBeforeCompare } from '../redux/slices/userSlice';
import { addTempProductsToCompare, updateTempCompareType } from '../redux/slices/compareSlice';

const productSliderDesktop = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 3,
  arrows: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true
      }
    }
  ]
};

const SimilarIndividualProductList = ({ data, index, scheduleType, modalRef }) => {
  const [showProductCount, setShowProductCount] = useState(7);
  const handleShowAllProducts = () => {
    setShowProductCount(data?.products?.length);
  };

  const renderProductsList = (products, categoryIndex, scheduleType) => {
    if (products && products.length > 0) {
      return products.map((item, productIndex) =>
        productIndex < showProductCount ? (
          <Product
            page="Similar Product List"
            productIndex={productIndex}
            categoryIndex={categoryIndex}
            key={`product_list_${item.product_id}`}
            data={item}
            inShop
            categorySlug={item.category_slug}
            source={SegmentSource.shopPage}
            openInPopup
            scheduleType={scheduleType}
            modalRef={modalRef}
            from="SimilarProducts"
          />
        ) : null
      );
    }
    return <div />;
  };
  return (
    <div className="all-products-slider relative row m-0 ">
      <Slider handleShowAllProducts={handleShowAllProducts} {...productSliderDesktop}>
        {renderProductsList(data.products, index, scheduleType)}
      </Slider>
    </div>
  );
};

const SimilarProductsList = ({ loading, products, currentProduct, scheduleType, tab, openInPopup, modalRef, onlySimilar, otherThanSimilar }) => {

  // const {
  //   dispatch } = useContext(appContext)
  const dispatchRedux = useDispatch()
  // const { trackEvent } = useSegment();
  const [popupLoader, setPopupLoader] = useState(false)
  const notificationsOverlap = useSelector(state => state.modal.notificationsOverlap)

  const renderProducts = (data, index, scheduleType) => {
    const handleCompare = data => {
      setPopupLoader(data.id);
      let loaderTimeout = setTimeout(() => {
        setPopupLoader(false);
        clearTimeout(loaderTimeout);
      }, 1200);

      // dispatch({
      //   type: 'addTempProductsToCompare',
      //   payload: [currentProduct, ...data.products]
      // });

      dispatchRedux(addTempProductsToCompare([currentProduct, ...data.products]))
      const url = '/compare';
      let tempTimeout = setTimeout(() => {
        notificationsOverlap.length ? dispatchRedux(pushNotificationOverlap({
          type: notificationTypes.comparePopup, data: { source: types[data.id] }
        })) : dispatchRedux(pushOnTopIfNotPresent({
          type: notificationTypes.comparePopup, data: { source: types[data.id] }
        }))
        let title = data.id == 1 ? `Compare with more products from ${data?.products?.[0]?.brand_names?.[0]}` : "Compare with similar products"
        // dispatch({ type: 'updateTempCompareType', payload: title });
        dispatchRedux(updateTempCompareType(title))
        dispatchRedux(increaseURLstack())
        // dispatch({ type: 'setUrlBeforeCompare', payload: { urlBeforeCompare: window.location.href } });
        dispatchRedux(setUrlBeforeCompare({ urlBeforeCompare: window.location.href }))
        window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
        clearTimeout(tempTimeout);
      }, 100);
    };

    if (data.products && data.products.length > 0) {
      return (
        <div key={`cat_${data.id}`} className="product-category ">
          <div className="row ">
            <div className="col-12 d-flex align-items-start relative">
              <h5 className="a-category-name bold title">
                {data?.id == 1 ? `More from ${data?.products?.[0]?.brand_names?.[0]}` : data.title}
              </h5>
              {[1, 2].includes(data.id) ? (
                <button className="compare-link btn-sm btn ml-2 d-flex align-items-center compare" onClick={() => handleCompare(data)}>
                  {popupLoader === data.id ? <InlineLoader text="Loading..." /> : <u>Compare All</u>}{' '}
                  <i className="icon icon-chevron-right-e"></i>
                </button>
              ) : null}
            </div>
          </div>
          <SimilarIndividualProductList data={data} index={index} scheduleType={scheduleType} modalRef={modalRef} />
        </div>
      );
    }
    return <div />;
  };

  return (
    <div className="">
      <div className={`one-rem-mt four-rem-pb  ${onlySimilar ? 's-product-container' : ''}`}>
        {loading ? (
          onlySimilar ? null : (
            <div className="text-center">
              <InlineLoader positionRelative className="big" />
            </div>
          )
        ) : (
          <>
            {products
              ?.filter(data => {
                if (onlySimilar) return data.id == 2;
                if (otherThanSimilar) return data.id !== 2;
                return true;
              })
              ?.map((data, index) => renderProducts(data, index, scheduleType))}
          </>
        )}
      </div>
    </div>
  );
};

export default SimilarProductsList;

const types = {
  1: 'More from the brand',
  2: 'Similar products',
  3: 'Frequently bought together'
};
