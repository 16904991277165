import React, { useContext, useEffect, useState } from 'react';
import { useFreightLimit, useZipCodeStatus } from '../hooks/app';
import SITE_TYPE from '../constants/siteType';
import { DEFAULT, GRASSDOOR } from '../constants/website';
import { useSelector } from 'react-redux';

const DeliveryNote = ({ className }) => {
  const { data: freightLimit, mutate } = useFreightLimit();
  const [remainingAmt, setRemainingAmt] = useState();
  // const {
  //   state: {
  //     cart: { cartData: { calculatedAmount, beforeTaxDiscountedAmount, totalBeforeDiscount } = {} }
  //   }
  // } = useContext(appContext);

  const { cartData: { calculatedAmount, beforeTaxDiscountedAmount, totalBeforeDiscount } = {} } = useSelector(state => state.cart) || {}

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    let remainingAmount = freightLimit;
    if (beforeTaxDiscountedAmount) {
      remainingAmount = freightLimit - beforeTaxDiscountedAmount;
    } else if (calculatedAmount) {
      if (Number(calculatedAmount) > 0 && totalBeforeDiscount > parseFloat(calculatedAmount)) {
        remainingAmount = freightLimit - totalBeforeDiscount;
      } else {
        remainingAmount = freightLimit - calculatedAmount;
      }
    }
    // remainingAmount += 1;
    setRemainingAmt(remainingAmount);
  }, [freightLimit, beforeTaxDiscountedAmount, calculatedAmount, totalBeforeDiscount]);

  // const remainingAmount = freightLimit - calculatedAmount;

  const variation = SITE_TYPE.ORIGINAL;
  // if ('8-C6MFlnQPKnmGBwGHkj7g') {
  // if (process.browser && window.google_optimize && optimizeLoaded && validZipcode) {
  //   // eslint-disable-next-line no-undef
  //   variation = (window.google_optimize && window.google_optimize.get('AlhQmcl5RYugKYAZkd_kKQ')) || SITE_TYPE.ORIGINAL;
  // }
  // console.log(optimizeLoaded, variation, process.browser && window.google_optimize, process.browser && window.google_optimize && window.google_optimize.get('2hHTcPKxQJmrczmGw0uodw'));
  // }
  if ((variation == SITE_TYPE.ORIGINAL && process.env.NEXT_PUBLIC_APP === DEFAULT) || process.env.NEXT_PUBLIC_DEFAULT_SITES) {
    if (!isNaN(freightLimit)) {
      if (remainingAmt > 0) {
        return (
          <>
            <div className={`delivery-threshold  ${className || ''}`}>
              <i className="icon-truck pr-2 truck-icon bold" />
              {`You're only $${remainingAmt.toFixed(2)} away from free delivery!`}
            </div>
          </>
        );
      }
      return (
        <>
          <div className={`delivery-threshold `}>
            <i className="icon-truck pr-2 truck-icon bold" />
            Congratulations, you get free delivery!
          </div>
        </>
      );
    }
  }
  return null;
};
export default DeliveryNote;
