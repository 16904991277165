import { useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

import envAppConfig from '../constants/envAppConfig';
import ORDER_STATUS_CHECK from '../constants/orderStatusCheck';
import notificationTypes from '../constants/modalNotificationConst';

import * as socketCalls from '../helpers/SocketHelper';

import { useHyperURL } from '../hooks/cart';
import storageService from '../services/storageService';
import { COD } from '../constants/paymentTypes';
import useSegment from '../hooks/segment';
import { setRollbarWarning } from '../helpers/rollBar';
import { useDispatch, useSelector } from 'react-redux';
import { setCouponData } from '../redux/slices/userSlice';
import { pushNotification, pushOnTopIfNotPresent } from '../redux/slices/modalSlice';
import { resetCardAndTips, setCustomerInfo, setOutOfStockProducts } from '../redux/slices/cartSlice';

const useSocket = () => {
  // const {
  //   dispatch,
  //   // state: {
  //   //   user: {
  //   //     deliveryDetails: { deliveryTypeSelected, postcode, city, state },
  //   //     isLoggedIn
  //   //   },
  //   //   cart: {
  //   //     customerData: { email, paymentType }
  //   //   }
  //   // }
  // } = useContext(appContext);

  const {
    deliveryDetails: { deliveryTypeSelected, postcode, city, state },
    isLoggedIn
  } = useSelector(state => state.user)
  const {
    customerData: { email, paymentType } = {}
  } = useSelector(state => state.cart) || {}

  const dispatchRedux = useDispatch()

  const { trackEvent, identifyEvent } = useSegment();
  const { data: hypurRedirectUrl } = useHyperURL();

  const router = useRouter();
  const isLoggedInRef = useRef(isLoggedIn);
  const {
    query: { giftcard }
  } = router;

  const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

  useEffect(() => {
    isLoggedInRef.current = isLoggedIn;
  }, [isLoggedIn]);

  async function checkout(obj, host = '') {

    const userInfoLocal = storageService.getUserData() || {};

    try {
      const data = giftcard ? await socketCalls.purchaseGiftCard(obj) : await socketCalls.checkOut(obj);

      const klaviyoObj = {
        integrations: {
          Klaviyo: false
        }
      };

      // trackEvent('order-placed', {}, isCheckoutOnly ? klaviyoObj : {});
      trackEvent('order-placed', {});

      const objId = { id: data.user_id, phone: userInfoLocal.phone, city_name: city, state_name: state, zip_pin: postcode };
      if (userInfoLocal.name) {
        objId.name = userInfoLocal.name;
        objId.firstName = userInfoLocal.first_name;
        objId.lastName = userInfoLocal.last_name;
      }
      if (email) {
        objId.email = email;
      }
      identifyEvent(data.user_id, objId);

      // dispatch({ type: 'resetCardAndTips' });
      dispatchRedux(resetCardAndTips())
      // Clear Payment Type, Wallet and Driver Tips
      // dispatch({ type: 'setCustomerInfo', payload: { paymentType: COD } });
      dispatchRedux(setCustomerInfo({ paymentType: COD }))
      storageService.setDriverTip(0);
      // Delete coupon data
      // dispatch({ type: 'setCouponData', payload: { couponData: {} } });
      dispatchRedux(setCouponData({ couponData: {} }))

      storageService.deleteCouponDetails();

      if (isCheckoutOnly) {
        storageService.deleteCouponFlag();
      }

      if (data && !giftcard) {
        const { order_id: orderId } = data || {};

        if (isCheckoutOnly && envAppConfig?.[host]?.CUSTOME_ORDER_DETAILS_URL) {
          router.push(`${envAppConfig[host].CUSTOME_ORDER_DETAILS_URL}${orderId}`);
        } else {
          router.push('/confirmation/[...orderId]', `/confirmation/${orderId}`);
        }
      }
      if (data && giftcard) {
        toast.success('Gift card purchased successfully. It will be sent to the recipient soon.');
        storageService.clearRecipients();
        dispatchRedux(pushNotification({
          type: notificationTypes.simplePopupGiftCard,
          data: {
            title: 'Gift card successfully purchased',
            text: 'Gift card purchased successfully. It will be sent to the recipient soon.'
          }
        }))
      }
      return data;
    } catch (error) {
      const { error: message, code } = error;

      toast.error(message);

      if ([11110000, 11110135, 7000001].includes(code)) {
        // 7000001: Hypur errors
        // 11110000: Stronghold requires minimum order amount of $1, Insufficient funds in stronghold account
        // Payment failed
        trackEvent('Payment Failed', {
          checkout_id: userInfoLocal.phone,
          phone: userInfoLocal.phone,
          shipping_method: deliveryTypeSelected || deliveryTypeSelected === 0 ? deliveryTypeSelected + 2 : 0,
          payment_method: paymentType,
          reason: message
        });
      }

      if (error.code === 11120012) {
        const outOfStockProducts = [];
        error.data.forEach(element => {
          const isSoldOut = parseInt(element.requested_quantity, 10) > parseInt(element.available_quantity, 10);
          if (isSoldOut) {
            outOfStockProducts.push({
              ordered_quantity: element.requested_quantity,
              available_quantity: element.available_quantity,
              is_sold_out: isSoldOut,
              product_id: element.product_id,
              name: element.product_name,
              image: element.product_image
            });
          }
        });
        // dispatch({ type: 'setOutOfStockProducts', payload: outOfStockProducts });
        dispatchRedux(setOutOfStockProducts(outOfStockProducts))
      }

      if (error.code === 7000002) {
        // location.href = hypurRedirectUrl;
        window.open(hypurRedirectUrl, '_blank');
      }

      if (error.code === 11120030) {
        // dispatch({ type: 'setCouponData', payload: { couponData: {} } });
        dispatchRedux(setCouponData({ couponData: {} }))
        storageService.deleteCouponDetails();
      }

      const newError = new Error('failed to checkout');
      setRollbarWarning("failed to checkout", error)
      throw newError;
    }
  }

  async function payByCard(paymentObject) {
    try {
      const data = await socketCalls.payByCard(paymentObject);
      toast.success('Payment Successful');
      return data;
    } catch (error) {
      // throw error;
      const { error: message, code } = error;
      toast.error(message);

      if (code === 11110135) {
        // ERROR_OCCURED_IN_PAYMENT
        trackEvent('Payment Failed', {
          order_id: paymentObject.order_id,
          phone: paymentObject.phone,
          shipping_method: paymentObject.deliveryType === 'scheduled' ? 2 : 1,
          payment_method: paymentObject.payment.type,
          reason: message
        });
      }
      throw error;
    }
  }

  async function requestCustomerStatus() {
    try {
      if (!isLoggedInRef.current) {
        return null;
      }

      const data = await socketCalls.requestCustomerStatus();

      if (data) {
        const { code = '' } = data;
        switch (code) {
          case ORDER_STATUS_CHECK.COMPLETE:
            return data;

          case ORDER_STATUS_CHECK.NEEDS_TO_BE_SIGNED:
            dispatchRedux(pushOnTopIfNotPresent({
              type: notificationTypes.orderCompletePopup,
              data: { ...data, signature: true }
            }))
            break;

          case ORDER_STATUS_CHECK.CAHNGE_PAC_NUMBER:
            dispatchRedux(pushOnTopIfNotPresent({ type: notificationTypes.pacPopup, data }))
            break;
          default:
        }
      }
    } catch (error) {
      console.error('socket.js requestCustomerStatus - error', error);
      setRollbarWarning('socket.js requestCustomerStatus - error', error);
    }
  }

  function getUpdatedZone(callback) {
    socketCalls.getUpdatedZone(callback);
  }

  function removeListnerUpdatedZone() {
    socketCalls.removeListnerUpdatedZone();
  }

  function joinRoom() {
    socketCalls.getCategories(postcode, (error, data) => { });
  }

  return {
    checkout,
    payByCard,
    requestCustomerStatus,
    getUpdatedZone,
    joinRoom,
    removeListnerUpdatedZone
  };
};

export default useSocket;
