export function extractDiscountDetails(cart) {
    const discountMap = {};

    cart.forEach(product => {
        const { offer_category_id, category_volume_discounts, quantity } = product;

        // Ignore products with offer_category_id = 0 (no discounts)
        if (!category_volume_discounts || offer_category_id === 0) {
            return;
        }

        // Initialize category if not present
        if (!discountMap[offer_category_id]) {
            discountMap[offer_category_id] = { discount: [], quantityInCart: 0, finalDiscount: 0 };
        }

        // Add product quantity to correct offer category
        discountMap[offer_category_id].quantityInCart += quantity;

        // Collect unique discount rules
        category_volume_discounts.forEach(discount => {
            const exists = discountMap[offer_category_id].discount.some(existing =>
                existing.units === discount.units && existing.discount === discount.discount
            );

            if (!exists) {
                discountMap[offer_category_id].discount.push(discount);
            }
        });
    });

    // Determine the final discount for each category based on total quantity
    Object.keys(discountMap).forEach(categoryId => {
        const category = discountMap[categoryId];

        // Sort discounts in descending order of `units` to find the best applicable one
        const sortedDiscounts = [...category.discount].sort((a, b) => b.units - a.units);

        for (const discount of sortedDiscounts) {
            if (category.quantityInCart >= discount.units) {
                category.finalDiscount = discount.discount;
                break;
            }
        }
    });

    return discountMap;
}

export function extractVolumeDiscountForCategory(cartItems, offerCategoryId) {
    const discountMap = {};

    cartItems.forEach(product => {
        if (product.offer_category_id === offerCategoryId && product.category_volume_discounts) {
            if (!discountMap[offerCategoryId]) {
                discountMap[offerCategoryId] = { discount: [], quantityInCart: 0, finalDiscount: 0 };
            }

            discountMap[offerCategoryId].quantityInCart += product.quantity;

            product.category_volume_discounts.forEach(discount => {
                const exists = discountMap[offerCategoryId].discount.some(existing =>
                    existing.units === discount.units && existing.discount === discount.discount
                );

                if (!exists) {
                    discountMap[offerCategoryId].discount.push(discount);
                }
            });
        }
    });

    if (!discountMap[offerCategoryId]) return null;

    // Determine best applicable discount
    const sortedDiscounts = discountMap[offerCategoryId].discount.sort((a, b) => b.units - a.units);
    for (const discount of sortedDiscounts) {
        if (discountMap[offerCategoryId].quantityInCart >= discount.units) {
            discountMap[offerCategoryId].finalDiscount = discount.discount;
            break;
        }
    }

    return discountMap[offerCategoryId];
}


export function extractVolumeDiscountDetails(cartItems) {
    const discountMap = {};

    cartItems.forEach(product => {
        if (product.offer_category_id && product.category_volume_discounts) {
            if (!discountMap[product.offer_category_id]) {
                discountMap[product.offer_category_id] = { discount: [], quantityInCart: 0, finalDiscount: 0 };
            }

            // Sum quantity for the same offer_category_id
            discountMap[product.offer_category_id].quantityInCart += product.quantity;

            // Collect unique discount rules
            product.category_volume_discounts.forEach(discount => {
                const exists = discountMap[product.offer_category_id].discount.some(existing =>
                    existing.units === discount.units && existing.discount === discount.discount
                );

                if (!exists) {
                    discountMap[product.offer_category_id].discount.push(discount);
                }
            });
        }
    });

    // Determine the best discount based on quantity
    Object.keys(discountMap).forEach(categoryId => {
        const category = discountMap[categoryId];

        const sortedDiscounts = [...category.discount].sort((a, b) => b.units - a.units);
        for (const discount of sortedDiscounts) {
            if (category.quantityInCart >= discount.units) {
                category.finalDiscount = discount.discount;
                break;
            }
        }
    });

    return discountMap;
}