/* global google */

import loadScript from './loadScript';

function convertPlacesDataToLocalPlacesModel(places) {
  const place = {};
  if (places) {
    if (places?.formatted_address) {
      place.address = places.formatted_address;
    }
    if (places?.place_id) {
      place.placeId = places.place_id;
    }
    if (places?.geometry) {
      place.latitude = places.geometry.location.lat();
      place.longitude = places.geometry.location.lng();
    }
    if (Array.isArray(places?.address_components)) {
      places.address_components.forEach(locationObj => {
        if (locationObj.types.includes('street_number')) {
          place.streetNumber = locationObj.short_name;
        } else if (locationObj.types.includes('route')) {
          place.streetName = locationObj.short_name;
        } else if (locationObj.types.includes('locality')) {
          place.city = locationObj.short_name;
        } else if (locationObj.types.includes('administrative_area_level_1')) {
          place.state = locationObj.short_name;
        } else if (locationObj.types.includes('country')) {
          place.country = locationObj.short_name;
        } else if (locationObj.types.includes('postal_code')) {
          place.postcode = locationObj.short_name;
        }
      });
    }
  }
  return place;
}

function getAddress(currentLocation) {
  return new Promise(async (resolve, reject) => {
    if (!window.google || typeof window.google === 'undefined') {
      await loadScript({
        url: `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}&callback=Function.prototype&v=3.exp&libraries=geometry,drawing,places`
      });
    }
    if (window.google && typeof google?.maps?.LatLng == 'function') {
      if (currentLocation.lat && currentLocation.lng) {
        const latlng = new google.maps.LatLng(currentLocation.lat, currentLocation.lng);
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: latlng }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results?.[0]) {
              resolve(convertPlacesDataToLocalPlacesModel(results[0]));
            } else {
              reject(new Error('Results not found', results));
            }
          } else {
            reject(new Error(`Geocode was not successful for the following reason: ${status}`));
          }
        });
      }
    } else {
      reject(new Error('google not defined'));
    }
  });
}

function getlocation() {
  return new Promise((resolve, reject) => {
    if (process.env.NEXT_PUBLIC_CHECKOUT_ONLY !== 'true' && navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const coords = pos.coords;
          resolve(coords);
        },
        error => {
          reject(error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 10000 }
      );
    } else {
      const error = new Error('no GPS');
      reject(error);
    }
  });
}

const getPlaceDetailsFromPlaceId = async placeId => {
  if (!window.google || typeof window.google === 'undefined') {
    await loadScript({
      url: `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}&callback=Function.prototype&v=3.exp&libraries=geometry,drawing,places`
    });
  }

  return new Promise((resolve, reject) => {
    try {
      if (typeof window.google === 'object') {
        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
        placesService.getDetails(
          {
            placeId,
            types: ['address'],
            fields: ['address_components', 'formatted_address', 'geometry', 'name', 'place_id']
          },
          place => {
            resolve(convertPlacesDataToLocalPlacesModel(place));
          }
        );
      }
    } catch (error) {
      reject(error);
    }
  });
};

export { convertPlacesDataToLocalPlacesModel, getAddress, getlocation, getPlaceDetailsFromPlaceId };
