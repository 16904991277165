// import { EXCISE_TAX_RATE, LOCAL_CANNABIS_TAX_RATE, SALES_TAX_RATE } from "../constants/sales";

import { extractVolumeDiscountDetails } from "../hooks/volumeDiscountHandler";

export function calculateTotalPriceWithTax(products, taxes, isLoggedIn) {
    let totalPreTax = 0;
    let totalPostTax = 0;
    const { EXCISE_TAX_RATE = 0, LOCAL_CANNABIS_TAX_RATE = 0, SALES_TAX_RATE = 0 } = taxes;

    // Extract unique category volume discounts based on cart
    const volumeDiscounts = extractVolumeDiscountDetails(products);

    products.forEach(product => {
        const {
            price = 0,
            quantity = 1,
            original_price: orignalPrice = 0,
            concentrated_weight,
            non_concentrated_weight,
            offer_category_id
        } = product;

        // Determine base price before tax
        const priceToUse = orignalPrice && orignalPrice !== 0 ? orignalPrice : price;

        // Apply category volume discount if available
        let finalDiscount = 0;
        if (!isLoggedIn && offer_category_id && volumeDiscounts[offer_category_id]) {
            finalDiscount = volumeDiscounts[offer_category_id].finalDiscount || 0;
        }
        const discountedPrice = priceToUse - (priceToUse * finalDiscount / 100);
        const productTotalPreTax = discountedPrice * quantity;
        totalPreTax += productTotalPreTax;

        // Determine if the product is cannabis-based
        const isCannabisProduct = concentrated_weight > 0 || non_concentrated_weight > 0;

        // Apply taxes after discount
        let productTotalPostTax = productTotalPreTax;
        if (isCannabisProduct) {
            productTotalPostTax += productTotalPostTax * EXCISE_TAX_RATE;
            productTotalPostTax += productTotalPostTax * LOCAL_CANNABIS_TAX_RATE;
        }
        productTotalPostTax += productTotalPostTax * SALES_TAX_RATE;

        // Accumulate post-tax total
        totalPostTax += productTotalPostTax;
    });

    return totalPostTax.toFixed(2);
}