import { useContext, useEffect, useRef } from 'react';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

import { ASAP, SCHEDULE, ASAP_AND_SCHEDULE } from '../constants/deliveryType';
import { getTab } from '../helpers/getDefaultTab';
import axios from '../utils/ajax';
import appContext from '../Context/appContext';
import storageService from '../services/storageService';
import hasValidDeliveryType from '../helpers/hasValidDeliveryType';
import getRouterPath from '../helpers/getRouterPath';
import * as ScheduleType from '../constants/scheduleWindow';

import notificationTypes from '../constants/modalNotificationConst';

import { useDeliveryDetails, useZipCodeStatus } from './app';

// import appConfig from '../appConfig';
import useSegment from './segment';
import applyDiscount from '../helpers/applyDiscount';
import useAPI from '../NetworkCall/API';
import { SCHEDULE_TYPE_ASAP, SCHEDULE_TYPE_FUTURE } from '../constants/scheduleType';
import { DEFAULT_GIFTCARD_ZIPCODE, DEFAULT_ZIPCODE, DEFAULT_ZONE_ID } from '../constants/default';
import { GET_CART, GET_WALLET_WITH_PURCHASE_REWARDS, GET_WALLET_WITH_PURCHASE_REWARDS_GUEST } from '../constants/requestUrls';
import getCartType from '../helpers/getCartType';
import { setRollbarWarning } from '../helpers/rollBar';
import getTransformedImageURLs from '../helpers/getTransformedImageURLs';
import { isRecommendedCartUpsellApplicable } from '../constants/feature';
import isLossOfWalletWithPurchaseRewardHelper from '../helpers/isLossOfWalletWithPurchaseRewardHelper';
import { wishlistToast } from '../helpers/wishlistToast';
import isMobile from '../helpers/isMobile';
import getProductIdsBeforeAddedTocart from '../helpers/getProductIds';
import { useDispatch, useSelector } from 'react-redux';
import { addUpdatingProducts, removeUpdatingProducts, setAddingNewProductToCart, setAddingToCart, setGuestAddingToCart, setBccPopupDismiss, setCartCount, setCartWidget, setCartWidgetLastAddedProduct, setCartWidgetProduct, setLastAddedProduct, setUpdateProductsWidget, setUpsellProducts, setUpsellRecommendedProducts, setUpdateTemp, setCartData, setRefreshCart, } from '../redux/slices/cartSlice';
import { pushNotification, pushNotificationOverlap } from '../redux/slices/modalSlice';
import { setIsSavedForLaterItemAddedOrRemoved } from '../redux/slices/wishlistSlice';
import { saveLocation, setFetchProductOnLoginFromPopupInfo } from '../redux/slices/userSlice';
import { attachAndRemoveClass } from '../helpers/categoriesHelper';
import { useConfigData } from '../Context/ConfigProvider';
import { extractVolumeDiscountForCategory } from './volumeDiscountHandler';

let cartWidgetInterval;

const fetcher = url => {
  return axios({ baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL, url, method: 'GET' }).then(res => res.data);
};

const noErrorfetcher = url => {
  return axios({ baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL, url, method: 'GET', hideError: true }).then(res => res.data);
};

const hiddenMessageFetcher = url => {
  return axios({ baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL, url, method: 'GET', hideError: true, hideSuccess: true })
    .then(res => res.data)
    .catch(err => {
      throw err;
    });
};

const hiddenMessageWithCancelFetcher = (url, cancelRequestUrl) => {
  return axios({
    baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL,
    url,
    method: 'GET',
    hideError: true,
    hideSuccess: true,
    requestUrl: cancelRequestUrl,
    cancelRequestUrl
  })
    .then(res => res.data)
    .catch(err => {
      throw err;
    });
};

const strongholdURLFetcher = (url, APP_URL) => {
  return axios({
    baseURL: process.env.NEXT_PUBLIC_SECURE_EC2_URL,
    url,
    method: 'POST',
    data: {
      redirect_url: `https://${APP_URL}/strongHoldRedirection`
    },
    hideError: true,
    hideSuccess: true
  })
    .then(res => res.data)
    .catch(err => {
      throw err;
    });
};

export const usePurchaseLimit = initialPurchaseLimit => {
  // const {
  //   state: {
  //     user: { shouldFetchProduct }
  //   }
  // } = useContext(appContext);
  const shouldFetchProduct = useSelector(state => state.user.shouldFetchProduct)

  const options = {
    revalidateOnFocus: false
  };

  if (initialPurchaseLimit) {
    options.initialData = { initialPurchaseLimit, dontModify: true };
  }

  const { data, mutate } = useSWR(
    shouldFetchProduct || !initialPurchaseLimit ? `/customers/purchase_limits` : null,
    fetcher,
    options
  );

  const purchaseLimit = data && data.dontModify ? data.initialPurchaseLimit : data;

  return {
    data: purchaseLimit,
    mutate
  };
};

export const fetchGiftCard = () => {
  const { data, mutate } = useSWR(`/products/gift_cards`, fetcher, {
    revalidateOnFocus: false
  });

  const giftCard = data;

  return {
    data: giftCard,
    mutate
  };
};

export const useFnFTnC = () => {
  const { data, mutate } = useSWR(`/cms/friendsandfamilytermsandconditions`, fetcher, {
    revalidateOnFocus: false
  });
  const termsAndConditions = data ? data.data : '';
  if (termsAndConditions) {
    let html = termsAndConditions.description;
    const first = html.indexOf('<script>') != -1 ? html.indexOf('<script>') + '<script>'.length : -1;
    const last = html.indexOf('</script>');
    const script = html.substring(first, last);
    html = html.replace('<script>', '');
    html = html.replace('</script>', '');
    html = html.replace(script, '');
    termsAndConditions.description = html;
    termsAndConditions.script = script;
  }

  return {
    data: termsAndConditions,
    mutate
  };
};

export const useUpsellProducts = (
  initialLocationAddress = false,
  initialUpsellProducts = false,
  initialDeliveryAddressDetails = false
) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  //       isLoggedIn,
  //       shouldFetchProduct,
  //       // favoriteStatusChangedForProduct
  //     },
  //     cart: { updateProductWidget, cartData: { cart_items: cartItems = [] } = {}, cartWidgetLastAddedProducts }
  //   }
  // } = useContext(appContext);

  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    isLoggedIn,
    shouldFetchProduct,
  } = useSelector(state => state.user)
  const { updateProductWidget, cartData: { cart_items: cartItems = [] } = {}, cartWidgetLastAddedProducts } = useSelector(state => state.cart) || {}
  const favoriteStatusChangedForProduct = useSelector(state => state.user.favoriteStatusChangedForProduct)

  const {
    data: { validZipcode }
  } = useZipCodeStatus(initialLocationAddress);

  const {
    data: { asapEnabled = true, scheduleEnabled = true }
  } = useDeliveryDetails(initialDeliveryAddressDetails);

  let deliveryType = 1;
  const cartType = getCartType(cartItems);
  deliveryType =
    cartType == ASAP_AND_SCHEDULE && asapEnabled && scheduleEnabled ? 3 : cartType == SCHEDULE || !asapEnabled ? 2 : 1;
  let giftCard = false;
  if (process.browser && window.location.search.includes('giftcard')) {
    giftCard = true;
  }
  const { data, mutate } = useSWR(
    (validZipcode && shouldFetchProduct) || (!initialUpsellProducts && validZipcode) || (giftCard && validZipcode)
      ? `/carts/upsell_products/${giftCard ? DEFAULT_GIFTCARD_ZIPCODE : postcode}/${deliveryType}`
      : null,
    noErrorfetcher,
    {
      // revalidateOnMount: false,
      revalidateOnFocus: false
    }
  );
  const upsellResponse = !data && initialUpsellProducts ? initialUpsellProducts : data || null;
  let upsellProducts = get(upsellResponse, 'upsell_products', []);
  const upsellZone = get(upsellResponse, 'zone_name', '');
  if (!isLoggedIn) {
    upsellProducts = upsellProducts.filter(function (element) {
      return !cartItems.find(function (item) {
        if (updateProductWidget) {
          return element.product_id === item.product_id && !cartWidgetLastAddedProducts.includes(element.product_id);
        }
        return element.product_id === item.product_id;
      });
    });
  }

  useEffect(() => {
    if (favoriteStatusChangedForProduct) {
      upsellProducts?.forEach(product => {
        if ((product?.bundle_id ? product.bundle_id : product?.product_id) == favoriteStatusChangedForProduct.productId) {
          product.is_favourite = favoriteStatusChangedForProduct.is_favourite;
        }
      });
    }
  }, [favoriteStatusChangedForProduct]);

  return {
    data: { upsellProducts, upsellZone },
    isLoading: !data,
    mutate
  };
};

export const useWalletWithPurchaseRewards = () => {
  // const {
  //   state: {
  //     user: {
  //       isLoggedIn,
  //       deliveryDetails: { deliveryTypeSelected }
  //     }
  //   }
  // } = useContext(appContext);

  const {
    isLoggedIn,
    deliveryDetails: { deliveryTypeSelected }
  } = useSelector(state => state.user)

  const { productsAmount } = useSelector(state => state.cart.cartData) || {}
  const { data: { isWalletCreditWithPurchaseApplicable = false } = {} } = useDeliveryDetails();

  const scheduleType = (deliveryTypeSelected % 10) + 2;

  const { data, error, mutate } = useSWR(
    isWalletCreditWithPurchaseApplicable
      ? isLoggedIn ? [`${GET_WALLET_WITH_PURCHASE_REWARDS}?schedule_type=${scheduleType}`, GET_WALLET_WITH_PURCHASE_REWARDS] :
        productsAmount != undefined ? [`${GET_WALLET_WITH_PURCHASE_REWARDS_GUEST}?schedule_type=${scheduleType}&subtotal=${productsAmount}`, GET_WALLET_WITH_PURCHASE_REWARDS_GUEST] : null
      : null,
    hiddenMessageWithCancelFetcher,
    { revalidateOnMount: true }
  );



  return {
    data: isLoggedIn ? data : data?.data,
    error,
    isLoading: !data && !error,
    mutate
  };
};

export const useModifyCart = ({
  page,
  initialLocationAddress = false,
  initialFreightLimit = false,
  initialPurchaseLimit = false,
  initialUpsellProducts = false,
  initialDeliveryAddressDetails = false
} = {}) => {

  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE, deliveryTypeSelected },
    deliveryDetails: initialDeliveryDetails,
    couponData,
    isLoggedIn,
    userSelectedTab
  } = useSelector(state => state.user)
  const {
    cartData = {},
    cartData: { cart_items: cartItems = [], amount: cartSubtotal = 0 } = {},
    showAllCartProductsWidget,
    cartWidgetLastAddedProducts,
    tempCart
  } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()

  const {
    data: { validZipcode }
  } = useZipCodeStatus(initialLocationAddress);

  const router = useRouter();

  const { trackEvent } = useSegment();

  const {
    data: {
      asapEnabled = true,
      hasGeofenceInventory,
      unified_menu,
      lastSlotCutOff,
      asapEndTime,
      asapStartTime,
      isWalletCreditWithPurchaseApplicable = false
    } = {}
  } = useDeliveryDetails(initialDeliveryAddressDetails);

  const { applyCoupon, getCart, switchCartItems, getUpsellPopularProducts, getSaveForLaterItems, removeFromSaveForLater } =
    useAPI(
      initialLocationAddress,
      initialFreightLimit,
      initialPurchaseLimit,
      initialUpsellProducts,
      initialDeliveryAddressDetails
    );

  const {
    data: { wallet_rewards: { tier_unlock_subtotal_amount: highestTierUnlocked } = {} } = {},
    mutate: mutateWalletWithPurchaseRewards
  } = useWalletWithPurchaseRewards({ isWalletCreditWithPurchaseApplicable });

  const couponRef = useRef(couponData);
  // const userSelectedTabRef = useRef(userSelectedTab);

  useEffect(() => {
    couponRef.current = couponData;
  }, [couponData]);

  // useEffect(() => {
  //   userSelectedTabRef.current = userSelectedTab;
  // }, [userSelectedTab]);

  function getScheduleType() {
    let scheduleType = ScheduleType.No_Inventory;
    const tab = getTab({ paramString: window.location.search, deliveryResponse: { lastSlotCutOff, asapEndTime, asapStartTime } });

    switch (getRouterPath(window.location.pathname)) {
      case '':
      case 'shop':
      case 'category':
      case 'brands':
        if (tab === SCHEDULE) {
          scheduleType = ScheduleType.Scheduled;
        } else {
          scheduleType = ScheduleType.ASAP;
        }
        break;
      case 'bundle':
      case 'los-angeles':
      case 'san-francisco':
        scheduleType = ScheduleType.Scheduled;
        break;
      case 'product':
        if (tab === SCHEDULE) {
          scheduleType = ScheduleType.Scheduled;
        } else if (tab === ASAP) {
          scheduleType = ScheduleType.ASAP;
        } else {
          scheduleType = ScheduleType.No_Inventory;
        }
        break;
      case 'cart':
      case 'checkout':
        if (deliveryTypeSelected == SCHEDULE_TYPE_ASAP) {
          scheduleType = ScheduleType.ASAP;
        } else {
          scheduleType = ScheduleType.Scheduled;
        }
        break;
      default:
        scheduleType = ScheduleType.No_Inventory;
        break;
    }
    if (!asapEnabled) {
      scheduleType = ScheduleType.Scheduled;
    }
    if (hasGeofenceInventory) {
      scheduleType = ScheduleType.ASAP;
    }
    return scheduleType;
  }

  function asyncDebounce(func, wait) {
    const debounced = debounce((resolve, reject, args) => {
      func(...args)
        .then(resolve)
        .catch(reject);
    }, wait);
    return (...args) =>
      new Promise((resolve, reject) => {
        debounced(resolve, reject, args);
      });
  }

  // const debouncedMutate = useRef(
  //   asyncDebounce(async () => {
  //     if (couponRef.current.discount_code) {
  //       await applyCoupon(couponRef.current.discount_code);
  //     } else {
  //       await getCart();
  //     }
  //   }, 2000)
  // );

  // const isDynamicOunceEligible = async ({ updatedCartItems }) => {
  //   let type;
  //   let createDynamicOunce = false;

  //   try {
  //     if (isZipps && updatedCartItems?.length) {
  //       let ounceProductCount = 0;
  //       let halfOunceCount = 0;

  //       updatedCartItems.every(item => {
  //         if (
  //           item.ounce_product_count === CustomOunceTypes.FULL.count ||
  //           ounceProductCount === CustomOunceTypes.HALF.count ||
  //           halfOunceCount === 2
  //         ) {
  //           return false;
  //         }

  //         if (item?.is_ounce && !item?.ounce_product_count) {
  //           ounceProductCount += Number(item.quantity);
  //         }

  //         if (item?.ounce_product_count) {
  //           halfOunceCount += 1;
  //         }

  //         return true;
  //       });

  //       createDynamicOunce = ounceProductCount >= CustomOunceTypes.HALF.count || halfOunceCount === 2;

  //       if (createDynamicOunce) {
  //         type = userSelectedTabRef.current === ASAP ? SCHEDULE_TYPE_ASAP : SCHEDULE_TYPE_FUTURE;
  //         const cartType = getCartType(updatedCartItems);

  //         if (cartType === ASAP) {
  //           type = SCHEDULE_TYPE_ASAP;
  //         } else if (cartType === SCHEDULE) {
  //           type = SCHEDULE_TYPE_FUTURE;
  //         }

  //         await checkProductAvailability({ postcode, scheduleType: type, checkAvailability: true });
  //       }
  //     }

  //     return {
  //       createDynamicOunce,
  //       dynamicOunceDeliveryType: type
  //     };
  //   } catch (error) {
  //     console.log(error);

  //     return {
  //       createDynamicOunce: false,
  //       dynamicOunceDeliveryType: undefined
  //     };
  //   }
  // };

  const debouncedMutate = async () => {
    await mutateWalletWithPurchaseRewards();

    if (couponRef.current.discount_code) {
      await applyCoupon(couponRef.current.discount_code);
    } else {
      // if(showAllCartProductsWidget) {
      await getCart();
      // }
    }
  };

  const setdataToCart = ({ product }) => {
    // Clear the loader
    // dispatch({ type: 'removeUpdatingProducts', payload: product.bundle_id ? product.bundle_id : product.product_details_id });

    dispatchRedux(removeUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id))
  };

  const renderOunceDiscountPopup = ({ ounceCreatedObj }) => {
    const { ounce_type: ounceType, schedule_type: ounceScheduleType } = ounceCreatedObj;
    dispatchRedux(pushNotification({
      type: notificationTypes.ounceDiscountPopup,
      data: {
        ounceCount: ounceType === CustomOunceTypes.HALF.type ? CustomOunceTypes.HALF.count : CustomOunceTypes.FULL.count,
        deliveryTypeText: ounceScheduleType === ScheduleType.ASAP ? 'express' : 'scheduled'
      }
    }))
  };

  const addUpdateDeleteCart = async ({ customUrl, apiMethod, payload, hideError, hideSuccess, cancelRequestUrl }) => {
    try {
      const response = await axios({
        url: customUrl || `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts`,
        method: apiMethod,
        data: payload,
        hideSuccess,
        hideError,
        cancelRequestUrl
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  const nonDebouncedCart = async ({ product, payload, type, hideSuccess = true, onSuccess, walletWithPurchaseProps }) => {

    switch (type) {
      case 'add': {
        try {
          // dispatch({ type: 'addUpdatingProducts', payload: product.bundle_id ? product.bundle_id : product.product_details_id });
          dispatchRedux(addUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id))
          // dispatch({ type: 'setAddingToCart', payload: true });
          dispatchRedux(setAddingToCart(true))
          // dispatch({ type: 'setAddingNewProductToCart', payload: true });
          dispatchRedux(setAddingNewProductToCart(true))

          payload && dispatchRedux(setUpdateTemp({
            ...payload,
            quantity: 1
          }))
          const response = await addUpdateDeleteCart({
            apiMethod: 'POST',
            payload,
            hideError: false,
            hideSuccess,
            cancelRequestUrl: GET_CART
          });
          response?.data?.data?.cart_id && dispatchRedux(setUpdateTemp({
            ...product, cart_id: response.data.data.cart_id
          }))


          if (response?.data?.is_product_removed_from_saved_for_later) {
            // dispatch({ type: 'setIsSavedForLaterItemAddedOrRemoved', payload: true });
            dispatchRedux(setIsSavedForLaterItemAddedOrRemoved(true));
            getSaveForLaterItems();
            wishlistToast(`Product Removed from Saved For Later list`);
          }

          setdataToCart({ product });

          const cartType = getCartType(cartItems);

          // dispatch({
          //   type: 'saveLocation',
          //   payload: {
          //     ...initialDeliveryDetails,
          //     deliveryTypeSelected: cartType !== SCHEDULE ? SCHEDULE_TYPE_ASAP : SCHEDULE_TYPE_FUTURE
          //   }
          // });
          dispatchRedux(saveLocation({
            ...initialDeliveryDetails,
            deliveryTypeSelected: cartType !== SCHEDULE ? SCHEDULE_TYPE_ASAP : SCHEDULE_TYPE_FUTURE
          }))
        } catch (error) {
          setdataToCart({ product });

          if (error?.data?.code === 60001001) {
            // code for case when requested qty not available, so max available/allowed qty added
            // dispatch({ type: 'setFetchProductOnLoginFromPopupInfo', payload: { shouldFetchProductOnLoginFromPopup: true } });
            dispatchRedux(setFetchProductOnLoginFromPopupInfo({ shouldFetchProductOnLoginFromPopup: true }))
            const ounceCreatedObj = false && error?.data?.ounce_created;

            if (ounceCreatedObj) {
              renderOunceDiscountPopup({ ounceCreatedObj });
            }
          } else {
            setRollbarWarning('Cart.js_nonDebouncedCart_addCatch', error);
            console.error('Cart.js_nonDebouncedCart_addCatch', error);
          }
        } finally {
          if (onSuccess) {
            onSuccess();
          }

          await debouncedMutate();

          // dispatch({ type: 'setAddingToCart', payload: false });
          dispatchRedux(setAddingToCart(false))
        }

        break;
      }

      case 'update': {
        let isRewardGettingLost = false;

        if (walletWithPurchaseProps) {
          isRewardGettingLost = isLossOfWalletWithPurchaseRewardHelper(walletWithPurchaseProps);
        }

        if (!isRewardGettingLost) {
          try {
            // dispatch({ type: 'setAddingToCart', payload: true });
            dispatchRedux(setAddingToCart(true))
            // dispatch({
            //   type: 'addUpdatingProducts',
            //   payload: product.bundle_id ? product.bundle_id : product.product_details_id
            // });
            dispatchRedux(addUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id))
            // dispatch({ type: 'setAddingToCart', payload: true });

            payload && dispatchRedux(setUpdateTemp({
              ...payload
            }))
            const response = await addUpdateDeleteCart({
              apiMethod: 'PATCH',
              payload,
              hideError: false,
              hideSuccess,
              cancelRequestUrl: GET_CART
            });

            setdataToCart({ product });
          } catch (error) {
            setdataToCart({ product });
            isLoggedIn && dispatchRedux(setRefreshCart(product?.bundle_id || product?.product_details_id))
            let tC = setTimeout(() => {
              dispatchRedux(setRefreshCart(null))
              clearTimeout(tC)
            }, 300)
            if (error?.data?.code === 60001001) {
              // code for case when requested qty not available, so max available/allowed qty added

              // dispatch({ type: 'setFetchProductOnLoginFromPopupInfo', payload: { shouldFetchProductOnLoginFromPopup: true } });
              dispatchRedux(setFetchProductOnLoginFromPopupInfo({ shouldFetchProductOnLoginFromPopup: true }))
              const ounceCreatedObj = false && error?.data?.ounce_created;
              if (ounceCreatedObj) {
                renderOunceDiscountPopup({ ounceCreatedObj });
              }
            } else {
              setRollbarWarning('Cart.js_nonDebouncedCart_updateCatch', error);
              console.error('Cart.js_nonDebouncedCart_updateCatch', error);
            }
          } finally {
            if (onSuccess) {
              onSuccess();
            }

            await debouncedMutate();

            // dispatch({ type: 'setAddingToCart', payload: false });
            dispatchRedux(setAddingToCart(false))
          }
        } else {
          setdataToCart({ product });

          // dispatch({ type: 'setAddingToCart', payload: false });
          dispatchRedux(setAddingToCart(false))
        }

        break;
      }

      case 'delete': {
        let isRewardGettingLost = false;

        if (walletWithPurchaseProps) {
          isRewardGettingLost = isLossOfWalletWithPurchaseRewardHelper(walletWithPurchaseProps);
        }

        if (!isRewardGettingLost) {
          // dispatch({ type: 'setAddingToCart', payload: true });
          dispatchRedux(setAddingToCart(true))
          // dispatch({ type: 'addUpdatingProducts', payload: product.bundle_id ? product.bundle_id : product.product_details_id });
          dispatchRedux(addUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id))
          // dispatch({ type: 'setAddingToCart', payload: true });

          try {


            payload && dispatchRedux(setUpdateTemp({
              ...{ cart_id: payload.cartID, ...product, quantity: 0 }
            }))
            const response = await addUpdateDeleteCart({
              customUrl: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/carts/${payload.cartID}/${postcode}`,
              apiMethod: 'DELETE',
              hideError: true,
              hideSuccess,
              cancelRequestUrl: GET_CART
            });

            // Set cart data
            const res = get(response, 'data', {});

            const cart = isEmpty(res)
              ? payload.cart
              : {
                cart_item_count: Number(res.cart_item_count) || 0,
                cart_items: res.cart_items
              };

            // dispatch({ type: 'setCartCount', payload: cart });
            dispatchRedux(setCartCount({ ...cartData, ...cart }))

            setdataToCart({ product });
          } catch (error) {
            setdataToCart({ product });

            setRollbarWarning('Cart.js_nonDebouncedCart_deleteCatch', error);
            console.error('Cart.js_nonDebouncedCart_deleteCatch', error);
          } finally {
            if (onSuccess) {
              onSuccess();
            }

            await debouncedMutate();

            // dispatch({ type: 'setAddingToCart', payload: false });
            dispatchRedux(setAddingToCart(false))
          }
        } else {
          setdataToCart({ product });

          // dispatch({ type: 'setAddingToCart', payload: false });
          dispatchRedux(setAddingToCart(false))
        }

        break;
      }

      default:
        break;
    }
  };

  const nonDebouncedGuestCart = async ({
    product,
    itemInCart,
    hideSuccess,
    onSuccess
  }) => {
    try {

      // dispatch({ type: 'addUpdatingProducts', payload: product.bundle_id ? product.bundle_id : product.product_details_id });
      dispatchRedux(addUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id));
      if (product.bundle_id) {

        storageService.updateCart({ id: product.bundle_id, quantity: product.quantity, isBundle: true });
      } else if (product.bulk_prices) {
        storageService.updateCart({
          id: product.product_id,
          quantity: product.quantity,
          price: product.price
        });
      } else {
        const flag = product.quantity >= itemInCart.quantity ? 'Increased' : 'Decreased';
        const updatedProduct = updateAvailedOffer(product, flag, itemInCart);
        storageService.updateCart({
          id: product.product_id,
          quantity: product.quantity,
          offerProduct: updatedProduct?.offer_product
        });
        setdataToCart({ product })
      }
    } catch (error) {
      setRollbarWarning('Cart.js_nonDebouncedGuestCart_updateCatch', error);
      console.error('Cart.js_nonDebouncedGuestCart_updateCatch', error);
    } finally {
      if (onSuccess) {
        onSuccess();
      }
      if (!hideSuccess) {
        toast.success('Successfully added to cart');
      }
      await getCart();
      setdataToCart({ product });
    }
  };

  const debouncedCart = useRef(debounce(nonDebouncedCart, 700, { leading: false, trailing: true }));
  const debouncedGuestCart = useRef(debounce(nonDebouncedGuestCart, 700, { leading: false, trailing: true }));

  function validateAction({ product, force, overrideScheduleType, dismissParentPopup, isFeaturedProduct, isMostPopularProduct }) {
    if (!validZipcode) {
      dispatchRedux(pushNotificationOverlap({ type: notificationTypes.deliverAddressPopup }))
      return true;
    }
    // const { cart_items: cartItems = [] } = cartData;
    if (!hasValidDeliveryType(cartItems, product)) {
      dispatchRedux(pushNotificationOverlap({ type: notificationTypes.switchDeliveryType, data: { dismissParentPopup } }))
      return true;
    }
    if (!force) {
      if (product.offer_category_id && !product.is_deal_available && !product.is_pre_deal_available) {
        if (product?.quantity) delete product.quantity;
        dispatchRedux(pushNotificationOverlap({
          type: notificationTypes.categoryVolumePricing,
          data: {
            ...product,
            page: product.list ? product.list : page,
            type: product.type,
            scheduleType: overrideScheduleType,
            isFeaturedProduct,
            isMostPopularProduct,
            dismissParentPopup
          }
        }))
        return true;
      }
      if (product.bulk_prices) {
        dispatchRedux(pushNotificationOverlap({
          type: notificationTypes.bulkQuantityPopup,
          data: {
            ...product,
            page: product.list ? product.list : page,
            type: product.type,
            scheduleType: overrideScheduleType,
            isFeaturedProduct,
            isMostPopularProduct,
            dismissParentPopup
          }
        }))
        return true;
      }
    }
    return false;
  }

  const updateCartItem = props => {
    const {
      product,
      scheduleType: overrideScheduleType,
      force,
      maxQty,
      list,
      immediate,
      hideSuccess = true,
      onSuccess,
      callback,
      type = 'home',
      isMostPopularProduct = false,
      fromPage = '',
      isFeaturedProduct = false,
      addedFromCompare,
      convertedFromWishlist = false,
      isLossOfRewardConfirmation,
      walletWithPurchaseProps,
    } = props;

    const { cart_items: cartItems = [] } = cartData;

    const cartType = getCartType(cartItems);
    let scheduleType = ScheduleType.ASAP;

    if (cartType == ASAP_AND_SCHEDULE) {
      scheduleType = ScheduleType.No_Inventory;
    } else if (cartType == SCHEDULE) {
      scheduleType = ScheduleType.Scheduled;
    }
    if (overrideScheduleType !== undefined) {
      scheduleType = overrideScheduleType;
    }

    const hasError = validateAction({ product, force, overrideScheduleType, isFeaturedProduct, isMostPopularProduct });
    if (hasError) {
      if (callback) {
        callback(hasError);
      }
      return;
    }

    let payload = {
      product_id: product.product_id,
      is_bundle: 0,
      schedule_type: scheduleType,
      zip_code: postcode
    };
    if (product.bundle_id) {
      payload = {
        product_id: product.bundle_id,
        is_bundle: 1,
        schedule_type: ScheduleType.Scheduled,
        zip_code: postcode
      };
    }
    if (product.bundle_id && product.asap) {
      payload = {
        product_id: product.bundle_id,
        is_bundle: 1,
        schedule_type: ScheduleType.ASAP,
        zip_code: postcode
      };
    }
    if (maxQty) {
      payload.pre_deal_limit = maxQty;
    }
    if (product.quantity) {
      payload.quantity = product.quantity;
    }
    if (list) {
      payload.list = list;
    }

    let itemInCart = null;
    let itemInCartTemp = null;
    if (product.bundle_id) {
      itemInCart = cartItems.find(item => item.bundle_id == product.bundle_id);
    } else {
      itemInCart = cartItems.find(item => item.product_details_id == product.product_details_id);
    }
    if (product.bundle_id) {
      itemInCartTemp = tempCart.find(item => item.bundle_id == product.bundle_id);
    } else {
      itemInCartTemp = tempCart.find(item => item.product_details_id == product.product_details_id);
    }

    if (itemInCart || itemInCartTemp) {
      let newDiscountedPrice = product.price;
      if (product.orignalPrice && product.orignalPrice !== product.price) {
        newDiscountedPrice = product.price;
      } else if (Array.isArray(couponData.product_ids) && couponData.product_ids.length) {
        if (findIndex(couponData.product_ids, o => parseInt(o, 10) === parseInt(product.product_id, 10)) > -1) {
          newDiscountedPrice = applyDiscount(product, product.price, couponData);
        }
      } else {
        newDiscountedPrice = applyDiscount(product, product.price, couponData);
      }

      // dispatch({ type: 'setBccPopupDismiss', payload: false });
      dispatchRedux(setBccPopupDismiss(false))
      trackEvent('Product Added', {
        recommendation_type: product.recommendation_type,
        cart_id: itemInCart?.cart_id || itemInCartTemp?.cart_id,
        product_id: product.bundle_id ? product.bundle_id : product.master_product_id,
        sku: product.bundle_id ? product.bundle_id : product.master_product_id,
        best_seller: isMostPopularProduct,
        best_seller_type: fromPage,
        category_frontend: product.custom_category_name ? product.custom_category_name : product.category_name,
        category: product.full_shop_category_name || '',
        name: product.name || product.product_name,
        product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
        brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
        variant: `${product.category_weight} ${product.category_unit}`,
        price: product.product_price || product.price,
        original_price: product.price_without_deal || product.price,
        strain_type: product.product_strain_type_name || '',
        quantity: product.quantity,
        coupon: couponData.discount_code || '',
        position: 1,
        url: `${origin}/product/${product.slug}`,
        image_url: getTransformedImageURLs(product.product_image || product.image, 'png').srcLarge,
        type,
        is_featured_product: isFeaturedProduct,
        ...(addedFromCompare ? { addedFromCompare: true } : {}),
        ...(convertedFromWishlist ? { convertedFromWishlist: true } : {})
      });

      if (isLoggedIn) {
        dispatchRedux(setGuestAddingToCart(false));
        const modifiedCartItems = cartItems.map(cartItem => {
          const modifiedCartItem = { ...cartItem };
          if (cartItem.cart_id === (itemInCart?.cart_id || itemInCartTemp?.cart_id)) {
            modifiedCartItem.quantity = Number(product.quantity) || 0;
          }
          return modifiedCartItem;
        });

        const cartItemCount = modifiedCartItems.reduce((total, cartItem) => {
          return total + (Number(cartItem.quantity) || 0);
        }, 0);

        const cart = { cart_item_count: cartItemCount, cart_items: modifiedCartItems };

        // dispatch({ type: 'setCartCount', payload: cart });
        dispatchRedux(setCartCount({ ...cartData, ...cart }))

        if (itemInCart?.cart_id || itemInCartTemp.cart_id) {
          payload.cart_id = itemInCartTemp?.cart_id || itemInCart?.cart_id;

          if (immediate) {
            nonDebouncedCart({
              product,
              payload,
              type: 'update',
              hideSuccess,
              onSuccess,
              walletWithPurchaseProps: !isLossOfRewardConfirmation
                ? { ...walletWithPurchaseProps, highestUnlockedTierSubtotal: highestTierUnlocked }
                : null
            });
          } else {
            debouncedCart.current({
              product,
              payload,
              type: 'update',
              hideSuccess,
              onSuccess,
              walletWithPurchaseProps: !isLossOfRewardConfirmation
                ? { ...walletWithPurchaseProps, highestUnlockedTierSubtotal: highestTierUnlocked }
                : null
            });
          }
        } else if (immediate) {
          nonDebouncedCart({ product, payload, type: 'add', hideSuccess, onSuccess });
        } else {
          debouncedCart.current({ product, payload, type: 'add', hideSuccess, onSuccess });
        }
      } else {

        const updatedProductsInCart = cartItems.map(cartItem => {
          const updatedProduct = { ...cartItem };

          // Update product quantity if it matches the selected product
          if (cartItem.product_id && cartItem.product_id === itemInCart.product_id) {
            updatedProduct.quantity = Number(product.quantity) || 0;
          }

          // Extract volume discount details for this product
          if (updatedProduct.offer_category_id && updatedProduct.category_volume_discounts) {
            const volumeDiscountData = extractVolumeDiscountForCategory(cartItems, updatedProduct.offer_category_id);

            if (volumeDiscountData) {
              updatedProduct.finalDiscount = volumeDiscountData.finalDiscount || 0;
            }
          }

          return updatedProduct;
        });


        const cartItemCount = updatedProductsInCart.reduce((total, cartItem) => {
          return total + (Number(cartItem.quantity) || 0);
        }, 0);

        const cart = { cart_item_count: cartItemCount, cart_items: updatedProductsInCart };

        // dispatch({ type: 'setCartCount', payload: cart });

        // dispatchRedux(setCartCount(cart));
        dispatchRedux(setCartData({ ...cartData, ...cart }))
        const finalItemInCart = { ...itemInCart, quantity: product.quantity };


        debouncedGuestCart.current({ product, itemInCart: finalItemInCart, type: 'update', hideSuccess, onSuccess });

      }
      cartWidgetProduct(product);
    }
  };

  const addToCart = ({
    product: productDetail,
    list,
    maxQty,
    force = false,
    callback,
    scheduleType: overrideScheduleType,
    immediate,
    hideSuccess = true,
    onSuccess,
    type = 'home',
    dismissParentPopup,
    isMostPopularProduct = false,
    fromPage = '',
    isFeaturedProduct = false,
    addedFromCompare = false,
    convertedFromWishlist = false,
    filtersforSegment = false,
    shouldValidate = true
  }) => {

    const product = { ...productDetail };

    const { cart_items: cartItems = [] } = cartData;

    let scheduleType = getScheduleType();

    const cartType = cartItems.length ? getCartType(cartItems) : getCartType([productDetail]);
    scheduleType = ScheduleType.ASAP;
    if (cartType == ASAP_AND_SCHEDULE) {
      scheduleType = ScheduleType.No_Inventory;
    } else if (cartType == SCHEDULE) {
      scheduleType = ScheduleType.Scheduled;
    }

    if (overrideScheduleType !== undefined) {
      scheduleType = overrideScheduleType;
    }

    // dispatch({ type: 'setLastAddedProduct', payload: { product } });
    dispatchRedux(setLastAddedProduct({ product }))
    if (showAllCartProductsWidget) {
      // dispatch({ type: 'setUpdateProductsWidget', payload: true });
      dispatchRedux(setUpdateProductsWidget(true))
      // dispatch({ type: 'setCartWidgetLastAddedProduct', payload: [...cartWidgetLastAddedProducts, product.product_id] });
      dispatchRedux(setCartWidgetLastAddedProduct([...cartWidgetLastAddedProducts, product.product_id]))
    }

    if (list) {
      // product.list = list;
      product = { ...product, list: list }
    }

    if (type) {
      // product.type = type;
      product = { ...product, type: type }
    }

    const hasError = shouldValidate
      ? validateAction({
        product,
        force,
        overrideScheduleType,
        dismissParentPopup,
        isFeaturedProduct,
        isMostPopularProduct
      })
      : false;

    if (callback) {
      callback(hasError);
    }

    if (hasError) {
      return "not-valid";
    }

    let payload = {
      product_id: product.product_id,
      is_bundle: 0,
      schedule_type: scheduleType
    };

    if (product.bundle_id) {
      payload = { product_id: product.bundle_id, is_bundle: 1, schedule_type: ScheduleType.Scheduled };
    }
    if (product.bundle_id && product.asap) {
      payload = { product_id: product.bundle_id, is_bundle: 1, schedule_type: ScheduleType.ASAP };
    }
    if (maxQty) {
      payload.pre_deal_limit = maxQty;
    }

    if (product.quantity > 1) {
      payload.quantity = product.quantity;
    }

    if (list) {
      payload.list = list;
    }

    if (type) {
      payload.type = type;
    }

    let itemInCart = null;
    if (product.bundle_id) {
      itemInCart = cartItems.find(item => item.bundle_id == product.bundle_id);
    } else {
      itemInCart = cartItems.find(item => item.product_details_id == product.product_details_id);
    }

    if (!itemInCart) {
      if (!showAllCartProductsWidget) {
        // dispatch({ type: 'setUpsellProducts', payload: [] });
        dispatchRedux(setUpsellProducts([]))
        // dispatch({ type: 'setUpsellRecommendedProducts', payload: {} });
        dispatchRedux(setUpsellRecommendedProducts({}))
      }

      if (isLoggedIn) {
        const modifiedProduct = {
          ...product,
          price: product.bundle_id ? product.bundle_selling_price : product.price,
          name: product.bundle_id ? product.bundle_name : product.name,
          category_currency: product.bundle_id ? product.product_currency : product.category_currency,
          quantity: Number(product.quantity) || 1
        };
        const finalCartItems = [...cartItems, modifiedProduct];
        const cartItemCount = finalCartItems.reduce((total, cartItem) => {
          return total + (Number(cartItem.quantity) || 0);
        }, 0);
        const cart = { ...cartData, cart_item_count: cartItemCount, cart_items: finalCartItems };

        // dispatch({ type: 'setCartCount', payload: cart });
        // dispatchRedux(setCartData(cart))
        dispatchRedux(setCartCount(cart))
        //dispatchRedux(setCartCount(cart))

        if (immediate) {
          nonDebouncedCart({ product, payload, type: 'add', hideSuccess, onSuccess });
        } else {
          debouncedCart.current({ product, payload, type: 'add', hideSuccess, onSuccess });
        }
      } else {
        // remove from save for later Items and update state        
        dispatchRedux(addUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id))

        if (storageService.isInSaveForLater(product)) {
          removeFromSaveForLater({ item: product, isMostPopularProduct, isFeaturedProduct, fromPage: page });
          getSaveForLaterItems();
        }

        const updatedProduct = updateAvailedOffer(product);
        if (updatedProduct?.offer_product?.length > 0) {
          updatedProduct.is_offer_allowed = 1;
        }
        if (!showAllCartProductsWidget) {
          // dispatch({ type: 'setUpsellProducts', payload: [] });
          dispatchRedux(setUpsellProducts([]))
          // dispatch({ type: 'setUpsellRecommendedProducts', payload: {} });
          dispatchRedux(setUpsellRecommendedProducts({}))
        }
        storageService.addToCart(updatedProduct);
        if (!hideSuccess) {
          toast.success('Successfully added to cart');
        }
        if (onSuccess) {
          onSuccess();
        }
        getCart();
      }

      let newDiscountedPrice = product.price;
      if (product.orignalPrice && product.orignalPrice !== product.price) {
        newDiscountedPrice = product.price;
      } else if (Array.isArray(couponData.product_ids) && couponData.product_ids.length) {
        if (findIndex(couponData.product_ids, o => parseInt(o, 10) === parseInt(product.product_id, 10)) > -1) {
          newDiscountedPrice = applyDiscount(product, product.price, couponData);
        }
      } else {
        newDiscountedPrice = applyDiscount(product, product.price, couponData);
      }
      attachAndRemoveClass(`product-image-${productDetail?.bundle_id ? productDetail.bundle_id : productDetail.master_product_id}`, 'add-animation')
      trackEvent('Product Added', {
        recommendation_type: product.recommendation_type,
        cart_id: 'new',
        list,
        product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
        product_id: product.bundle_id ? product.bundle_id : product.master_product_id,
        sku: product.bundle_id ? product.bundle_id : product.master_product_id,
        best_seller: isMostPopularProduct,
        best_seller_type: fromPage,
        category_frontend: product.custom_category_name ? product.custom_category_name : product.category_name,
        category: product.full_shop_category_name || '',
        name: product.product_name,
        brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
        variant: `${product.product_weight} ${product.product_unit}`,
        price: product.is_pre_deal_available ? product.price : newDiscountedPrice || product.product_price || product.price,
        original_price: product.price_without_deal || product.price,
        strain_type: product.product_strain_type_name || '',
        quantity: 1,
        coupon: couponData.discount_code || '',
        position: 1,
        url: `${origin}/product/${product.slug}`,
        image_url: getTransformedImageURLs(
          product.product_image || (product.images && product.images.length && product.images[0]),
          'png'
        ).srcLarge,
        type,
        is_featured_product: isFeaturedProduct,
        ...(addedFromCompare ? { addedFromCompare: true } : {}),
        ...(convertedFromWishlist ? { convertedFromWishlist: true, convertedList: type } : {}),
        ...(filtersforSegment ? { filterApplied: filtersforSegment } : {})
      });
    } else {
      updateCartItem({
        product: {
          ...product,
          quantity: (Number(itemInCart.quantity) || 1) + 1
        },
        scheduleType,
        force,
        maxQty,
        list,
        hideSuccess,
        onSuccess,
        isMostPopularProduct,
        isFeaturedProduct
      });
    }
    cartWidgetProduct(product);
  };

  // TODO: product add remove segment event

  const deleteCartItem = props => {
    const {
      product,
      immediate,
      type = 'home',
      list,
      isFeaturedProduct = false,
      isMostPopularProduct = false,
      onSuccess,
      isLossOfRewardConfirmation,
      walletWithPurchaseProps
    } = props;

    if (!validZipcode) {
      dispatchRedux(pushNotificationOverlap({ type: notificationTypes.deliverAddressPopup }))
      return true;
    }
    const { cart_items: cartItems = [], amount } = cartData;
    let itemInCart = null;

    if (product.ounce_id) {
      itemInCart = cartItems.find(item => item.ounce_id == product.ounce_id);
    } else if (product.bundle_id) {
      itemInCart = cartItems.find(item => item.bundle_id == product.bundle_id);
    } else {
      itemInCart = cartItems.find(item => item.product_details_id == product.product_details_id);
    }

    if (itemInCart) {
      trackEvent('Product Removed', {
        cart_id: product.cart_id,
        product_id: product.bundle_id ? product.bundle_id : product.master_product_id,
        sku: product.bundle_id ? product.bundle_id : product.master_product_id,
        category: product.full_shop_category_name || '',
        category_frontend: product.category_name,
        name: product.name,
        product_attributes: Array.isArray(product.product_attributes_second) ? product.product_attributes_second.join('/') : '',
        brand: Array.isArray(product.brand_names) ? product.brand_names.join('/') : product.brand_slug || '',
        variant: product.is_pre_deal_available
          ? `${product.product_weight} ${product.product_unit}`
          : `${product.category_weight} ${product.category_unit}`,
        price: product.product_price || product.price,
        original_price: product.price_without_deal || product.price,
        strain_type: product.product_strain_type_name || '',
        quantity: 1,
        coupon: couponData.discount_code || '',
        position: 1,
        url: `${origin}/product/${product.slug}`,
        image_url: product.image,
        type,
        list,
        currency: product?.[0]?.category_currency === '$' ? 'usd' : product?.[0]?.category_currency || 'usd',
        is_featured_product: isFeaturedProduct,
        best_seller: isMostPopularProduct,
        best_seller_type: type
      }, true);

      // dispatch({ type: 'setCartWidget', payload: false });
      dispatchRedux(setCartWidget(false))

      if (showAllCartProductsWidget) {
        // dispatch({ type: 'setUpdateProductsWidget', payload: true });
        dispatchRedux(setUpdateProductsWidget(true))
      }

      if (isLoggedIn) {
        const modifiedCartItems = cartItems.filter(cartItem => cartItem.product_details_id !== product.product_details_id);

        const cartItemCount = modifiedCartItems.reduce((total, cartItem) => {
          return total + (Number(cartItem.quantity) || 0);
        }, 0);

        const cart = { cart_item_count: cartItemCount, cart_items: modifiedCartItems };

        // dispatch({ type: 'addUpdatingProducts', payload: product.bundle_id ? product.bundle_id : product.product_details_id });
        dispatchRedux(addUpdatingProducts(product.bundle_id ? product.bundle_id : product.product_details_id))

        if (immediate) {
          nonDebouncedCart({
            product,
            type: 'delete',
            payload: { cartID: itemInCart.cart_id, cart },
            onSuccess,
            walletWithPurchaseProps: !isLossOfRewardConfirmation
              ? { ...walletWithPurchaseProps, highestUnlockedTierSubtotal: highestTierUnlocked }
              : null
          });
        } else {
          debouncedCart.current({
            product,
            type: 'delete',
            payload: { cartID: itemInCart.cart_id, cart },
            onSuccess,
            walletWithPurchaseProps: !isLossOfRewardConfirmation
              ? { ...walletWithPurchaseProps, highestUnlockedTierSubtotal: highestTierUnlocked }
              : null
          });
        }
      } else {

        storageService.deleteProduct(product);
        getCart();
      }
    }
  };

  const switchCartItemsCart = async ({
    product,
    type,
    quantity = null,
    singleOption = null,
    hideSuccess = true,
    onOunceCreationSucess = null
  }) => {
    try {
      await switchCartItems({
        product,
        type,
        quantity,
        singleOption,
        hideSuccess,
        onOunceCreationSucess
      });
      cartWidgetProduct(product);
    } catch (error) {
      setRollbarWarning('Cart.js_switchCartItemsCart_tryCatch', error);
      console.error('Cart.js_switchCartItemsCart_tryCatch', error);

      throw error;
    }
  };

  function cartWidgetProduct(product) {
    if (
      !['/checkout', '/cart'].includes(router.pathname) &&
      // notificationsOverlap.length == 0 &&
      // notifications.length == 0 &&
      !showAllCartProductsWidget
    ) {
      // dispatch({ type: 'setCartWidgetProduct', payload: product.bundle_id ? product.bundle_id : product.product_id });
      dispatchRedux(setCartWidgetProduct(product.bundle_id ? product.bundle_id : product.product_id))
      // dispatch({ type: 'setCartWidget', payload: false });
      dispatchRedux(setCartWidget(false))
      if (cartWidgetInterval) {
        clearInterval(cartWidgetInterval);
      }
      cartWidgetInterval = setInterval(hideWidgetAfterFiveSeconds, 9000);
    }
  }

  function hideWidgetAfterFiveSeconds() {
    const cartWidget = document.getElementById('cart-widget');
    const isFocused = !!(cartWidget && cartWidget.parentNode.querySelector(':hover') === cartWidget);
    if (!isFocused && !isMobile()) {
      trackEvent('Added to Cart Closed', { type: 'Auto Close' }, true);
      // dispatch({ type: 'setCartWidget', payload: false });
      dispatchRedux(setCartWidget(false))
      clearInterval(cartWidgetInterval);
    }
  }

  return {
    addToCart,
    updateCartItem,
    deleteCartItem,
    validateAction,
    switchCartItemsCart
  };
};

export const useHyperURL = () => {
  const appConfig = useConfigData()
  const redirectUrl = `https://${appConfig.APP_URL}/hypurRedirection`;
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE },
  //       isLoggedIn
  //     },
  //     cart: { cartData: { hypur_blocked: hypurBlocked = true } = {} }
  //   }
  // } = useContext(appContext);

  const {
    deliveryDetails: { postcode = DEFAULT_ZIPCODE },
    isLoggedIn
  } = useSelector(state => state.user)
  const { cartData: { hypur_blocked: hypurBlocked = true } = {} } = useSelector(state => state.cart) || {}

  let giftCard = false;
  if (process.browser && window.location.search.includes('giftcard')) {
    giftCard = true;
  }
  const { data } = useSWR(
    isLoggedIn && !hypurBlocked ? `/hypur/${giftCard ? DEFAULT_GIFTCARD_ZIPCODE : postcode}?redirectUrl=${redirectUrl}` : null,
    hiddenMessageFetcher
  );
  const hypurRedirectUrl = get(data, 'data.url', null);

  return {
    data: hypurRedirectUrl
  };
};

export const useStrongholdURL = () => {
  const appConfig = useConfigData();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { createStrongHold = 0 } = useSelector(state => state.cart?.cartData) || {}
  const { data } = useSWR(isLoggedIn && !createStrongHold ? `/strong_hold/customer/pay_links` : null, (url) => strongholdURLFetcher(url, appConfig.APP_URL));
  const strongholdRedirectUrl = get(data, 'link', null);
  return {
    data: strongholdRedirectUrl
  };
};

export const updateAvailedOffer = (product, flag = 'Increased', itemInCart) => {
  let remainingQuantity = product?.quantity ? product?.quantity : 1;
  const { offer_product } = product;

  const clonedOfferProduct = offer_product?.map(singleOffer => ({ ...singleOffer, is_avail: 0 })) || [];

  clonedOfferProduct.sort((a, b) => b.buy_products_buy_quantity - a.buy_products_buy_quantity);

  clonedOfferProduct.forEach(singleOffer => {
    if (remainingQuantity >= singleOffer?.buy_products_buy_quantity) {
      const divisor = Math.floor(remainingQuantity / singleOffer?.buy_products_buy_quantity);
      singleOffer.is_avail += divisor;
      remainingQuantity %= singleOffer?.buy_products_buy_quantity;
    }
  });

  clonedOfferProduct.sort((a, b) => a.buy_products_buy_quantity - b.buy_products_buy_quantity);
  clonedOfferProduct.sort((a, b) => b.is_avail - a.is_avail);

  product.offer_product = clonedOfferProduct;
  return product;
};


// export const useCartConcentration = () => {
//   // const {
//   //   state: {
//   //     cart: {
//   //       cartData: {
//   //         cart_items: cartItems = [],
//   //         concentrateUnit: loggedInConcentrateUnit = 'g',
//   //         nonConcentrateUnit: loggedInNonConcentrateUnit = 'g',
//   //         cannabisLimits: { concentrate: loggedInConcentrateLimit = 0, non_concentrate: loggedInNonConcentrateLimit = 0 } = {},
//   //         cartContents: { concentrate: concentratesInCart = 0, non_concentrate: nonConcentratesInCart = 0 } = {},
//   //         orderedContents: {
//   //           concentrate: concentratesAlreadyOrderedToday = 0,
//   //           non_concentrate: nonConcentratesAlreadyOrderedToday = 0
//   //         } = {}
//   //       } = {}
//   //     },
//   //     user: { isLoggedIn }
//   //   }
//   // } = useContext(appContext);

//   const isLoggedIn = useSelector(state => state.user.isLoggedIn)
//   const {
//     cart_items: cartItems = [],
//     concentrateUnit: loggedInConcentrateUnit = 'g',
//     nonConcentrateUnit: loggedInNonConcentrateUnit = 'g',
//     cannabisLimits: { concentrate: loggedInConcentrateLimit, non_concentrate: loggedInNonConcentrateLimit } = {},
//     cartContents: { concentrate: concentratesInCart, non_concentrate: nonConcentratesInCart } = {},
//     orderedContents: {
//       concentrate: concentratesAlreadyOrderedToday,
//       non_concentrate: nonConcentratesAlreadyOrderedToday
//     } = {}
//   } = useSelector(state => state.cart?.cartData) || {}

//   useEffect(() => {
//     console.log("concentrates", nonConcentratesAlreadyOrderedToday, concentratesAlreadyOrderedToday, loggedInConcentrateLimit, loggedInNonConcentrateLimit, concentratesInCart, nonConcentratesInCart);

//   }, [loggedInConcentrateUnit, nonConcentratesAlreadyOrderedToday, concentratesAlreadyOrderedToday, loggedInNonConcentrateUnit, loggedInConcentrateLimit, loggedInNonConcentrateLimit, concentratesInCart, nonConcentratesInCart])

//   const {
//     data: {
//       cannabisLimits: { concentrate: nonLoggedInConcentrateLimit = 0, non_concentrate: nonLoggedInNonConcentrateLimit = 0 } = {},
//       concentrateUnit: nonLoggedInConcentrateUnit = 'g',
//       nonConcentrateUnit: nonLoggedInNonConcentrateUnit = 'g'
//     } = {}
//   } = usePurchaseLimit();

//   const totalConcentratesWeight = (() => {
//     let tempTotalConcentratesWeight = 0;

//     if (isLoggedIn) {
//       tempTotalConcentratesWeight = concentratesInCart + concentratesAlreadyOrderedToday;
//     } else {
//       cartItems.forEach(item => {
//         tempTotalConcentratesWeight += item.concentrated_weight;
//       });
//     }

//     return tempTotalConcentratesWeight;
//   })();

//   const totalNonConcentratesWeight = (() => {
//     let tempTotalNonConcentratesWeight = 0;

//     if (isLoggedIn) {
//       tempTotalNonConcentratesWeight = nonConcentratesInCart + nonConcentratesAlreadyOrderedToday;
//     } else {
//       cartItems.forEach(item => {
//         tempTotalNonConcentratesWeight += item.non_concentrated_weight;
//       });
//     }

//     return tempTotalNonConcentratesWeight;
//   })();

//   const maxConcentratesWeightAllowed = loggedInConcentrateLimit || nonLoggedInConcentrateLimit || 0;
//   const maxNonConcentratesWeightAllowed = loggedInNonConcentrateLimit || nonLoggedInNonConcentrateLimit || 0;

//   const finalConcentrateUnit = loggedInConcentrateUnit || nonLoggedInConcentrateUnit;
//   const finalNonConcentrateUnit = loggedInNonConcentrateUnit || nonLoggedInNonConcentrateUnit;

//   const concentratesWeightPercentage =
//     totalConcentratesWeight && maxConcentratesWeightAllowed ? (totalConcentratesWeight / maxConcentratesWeightAllowed) * 100 : 0;
//   const nonConcentratesWeightPercentage =
//     totalNonConcentratesWeight && maxNonConcentratesWeightAllowed
//       ? (totalNonConcentratesWeight / maxNonConcentratesWeightAllowed) * 100
//       : 0;

//   return {
//     totalConcentratesWeight,
//     totalNonConcentratesWeight,
//     maxConcentratesWeightAllowed,
//     maxNonConcentratesWeightAllowed,
//     finalConcentrateUnit,
//     finalNonConcentrateUnit,
//     concentratesWeightPercentage,
//     nonConcentratesWeightPercentage
//   };
// };

export const useCartConcentration = () => {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);

  const {
    cart_items: cartItems = [],
    concentrateUnit: loggedInConcentrateUnit = 'g',
    nonConcentrateUnit: loggedInNonConcentrateUnit = 'g',
    cannabisLimits: { concentrate: loggedInConcentrateLimit, non_concentrate: loggedInNonConcentrateLimit } = {},
    cartContents: { concentrate: concentratesInCart, non_concentrate: nonConcentratesInCart } = {},
    orderedContents: {
      concentrate: concentratesAlreadyOrderedToday,
      non_concentrate: nonConcentratesAlreadyOrderedToday
    } = {}
  } = useSelector(state => state.cart?.cartData) || {};

  // Refs to hold previous values
  const prevConcentratesInCartRef = useRef(concentratesInCart);
  const prevNonConcentratesInCartRef = useRef(nonConcentratesInCart);
  const prevConcentratesAlreadyOrderedRef = useRef(concentratesAlreadyOrderedToday);
  const prevNonConcentratesAlreadyOrderedRef = useRef(nonConcentratesAlreadyOrderedToday);
  const prevLoggedInConcentrateLimitRef = useRef(loggedInConcentrateLimit);
  const prevLoggedInNonConcentrateLimitRef = useRef(loggedInNonConcentrateLimit);

  // Update refs only if new values are not undefined
  useEffect(() => {
    if (concentratesInCart !== undefined) prevConcentratesInCartRef.current = concentratesInCart;
    if (nonConcentratesInCart !== undefined) prevNonConcentratesInCartRef.current = nonConcentratesInCart;
    if (concentratesAlreadyOrderedToday !== undefined) prevConcentratesAlreadyOrderedRef.current = concentratesAlreadyOrderedToday;
    if (nonConcentratesAlreadyOrderedToday !== undefined) prevNonConcentratesAlreadyOrderedRef.current = nonConcentratesAlreadyOrderedToday;
    if (loggedInConcentrateLimit !== undefined) prevLoggedInConcentrateLimitRef.current = loggedInConcentrateLimit;
    if (loggedInNonConcentrateLimit !== undefined) prevLoggedInNonConcentrateLimitRef.current = loggedInNonConcentrateLimit;
  }, [
    concentratesInCart,
    nonConcentratesInCart,
    concentratesAlreadyOrderedToday,
    nonConcentratesAlreadyOrderedToday,
    loggedInConcentrateLimit,
    loggedInNonConcentrateLimit
  ]);

  // Use previous values if current ones are undefined
  const currentConcentratesInCart = concentratesInCart !== undefined ? concentratesInCart : prevConcentratesInCartRef.current;
  const currentNonConcentratesInCart = nonConcentratesInCart !== undefined ? nonConcentratesInCart : prevNonConcentratesInCartRef.current;
  const currentConcentratesAlreadyOrdered = concentratesAlreadyOrderedToday !== undefined ? concentratesAlreadyOrderedToday : prevConcentratesAlreadyOrderedRef.current;
  const currentNonConcentratesAlreadyOrdered = nonConcentratesAlreadyOrderedToday !== undefined ? nonConcentratesAlreadyOrderedToday : prevNonConcentratesAlreadyOrderedRef.current;
  const currentLoggedInConcentrateLimit = loggedInConcentrateLimit !== undefined ? loggedInConcentrateLimit : prevLoggedInConcentrateLimitRef.current;
  const currentLoggedInNonConcentrateLimit = loggedInNonConcentrateLimit !== undefined ? loggedInNonConcentrateLimit : prevLoggedInNonConcentrateLimitRef.current;

  const {
    data: {
      cannabisLimits: { concentrate: nonLoggedInConcentrateLimit = 0, non_concentrate: nonLoggedInNonConcentrateLimit = 0 } = {},
      concentrateUnit: nonLoggedInConcentrateUnit = 'g',
      nonConcentrateUnit: nonLoggedInNonConcentrateUnit = 'g'
    } = {}
  } = usePurchaseLimit();

  const totalConcentratesWeight = (() => {
    let tempTotalConcentratesWeight = 0;

    if (isLoggedIn) {
      tempTotalConcentratesWeight = currentConcentratesInCart + currentConcentratesAlreadyOrdered;
    } else {
      cartItems.forEach(item => {
        tempTotalConcentratesWeight += item.concentrated_weight;
      });
    }

    return tempTotalConcentratesWeight;
  })();

  const totalNonConcentratesWeight = (() => {
    let tempTotalNonConcentratesWeight = 0;

    if (isLoggedIn) {
      tempTotalNonConcentratesWeight = currentNonConcentratesInCart + currentNonConcentratesAlreadyOrdered;
    } else {
      cartItems.forEach(item => {
        tempTotalNonConcentratesWeight += item.non_concentrated_weight;
      });
    }

    return tempTotalNonConcentratesWeight;
  })();

  const maxConcentratesWeightAllowed = currentLoggedInConcentrateLimit || nonLoggedInConcentrateLimit || 0;
  const maxNonConcentratesWeightAllowed = currentLoggedInNonConcentrateLimit || nonLoggedInNonConcentrateLimit || 0;

  const finalConcentrateUnit = loggedInConcentrateUnit || nonLoggedInConcentrateUnit;
  const finalNonConcentrateUnit = loggedInNonConcentrateUnit || nonLoggedInNonConcentrateUnit;

  const concentratesWeightPercentage =
    totalConcentratesWeight && maxConcentratesWeightAllowed ? (totalConcentratesWeight / maxConcentratesWeightAllowed) * 100 : 0;
  const nonConcentratesWeightPercentage =
    totalNonConcentratesWeight && maxNonConcentratesWeightAllowed
      ? (totalNonConcentratesWeight / maxNonConcentratesWeightAllowed) * 100
      : 0;

  return {
    totalConcentratesWeight,
    totalNonConcentratesWeight,
    maxConcentratesWeightAllowed,
    maxNonConcentratesWeightAllowed,
    finalConcentrateUnit,
    finalNonConcentrateUnit,
    concentratesWeightPercentage,
    nonConcentratesWeightPercentage
  };
};
