export default {
  PLACED: 1,
  ASSIGNED: 2,
  DISPATCHED: 3,
  DELIVERED: 4,
  CANCELLED: 5,
  PAYMENT_FAILED: 6,
  FAILED: 7,
  INTRANSIT_ORDER_CANCELLED: 8, // by customer or admin
  ON_HOLD: 9,
  DRIVER_REACHED: 11
};
export const orderProcessing = [1, 2, 3];
